import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { loader } from 'react-global-loader';

import { AppointmentParsed, Room } from '@/@types';
import {
  AppointmentForm,
  NewAppointmentInformation,
} from '@/features/AppointmentActions/components';
import { AppointmentDetailsDrawer } from '../components';
import { useAppointmentTypeContext, useLocation, useToastContext } from '@/contexts';
import { useProviders, useRooms, useScheduler } from '@/hooks';
import { ConfirmDeleteDialog } from '@/components';

type AppointmentActionsContextType = {
  onAppointmentDetailsClick: (appointment: AppointmentParsed) => void;
  onAppointmentCreateClick: (newApptInfo: NewAppointmentInformation) => void;
  onAppointmentEditClick: (
    appointment: AppointmentParsed | AppointmentParsed[] | undefined,
    onSubmitCallback: () => void
  ) => void;
  onAppointmentDuplicateClick: (
    appointment: AppointmentParsed | AppointmentParsed[] | undefined,
    submitCallback: () => void
  ) => void;
  onAppointmentDeleteClick: (appointment: AppointmentParsed[], submitCallback: () => void) => void;
};

const Context = createContext<AppointmentActionsContextType>({
  onAppointmentDetailsClick: () => {},
  onAppointmentCreateClick: () => {},
  onAppointmentEditClick: () => {},
  onAppointmentDuplicateClick: () => {},
  onAppointmentDeleteClick: () => {},
});

export const AppointmentActionsContext = ({ children }: PropsWithChildren) => {
  const { selectedRegion } = useLocation();
  const { appointmentTypesOptionsList: appointmentTypes } = useAppointmentTypeContext();
  const { providers } = useProviders();
  const { rooms: roomsOptionsList } = useRooms();
  const { deleteMultipleAppointments } = useScheduler();
  const { toast } = useToastContext();

  const roomsFilteredOptions: Room[] = useMemo(() => {
    return roomsOptionsList.filter((room) => room.location?.id === selectedRegion?.id) || [];
  }, [roomsOptionsList, selectedRegion]);

  const [appointmentData, setAppointmentData] = useState<AppointmentParsed | undefined>();
  const [appointmentEditionData, setAppointmentEditionData] = useState<
    AppointmentParsed | AppointmentParsed[]
  >();
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [showFormDrawer, setShowFormDrawer] = useState(false);
  const [showConfirmAppointmentDeleteDialog, setShowConfirmAppointmentDeleteDialog] =
    useState(false);
  const [appointmentsToDelete, setAppointmentsToDelete] = useState<AppointmentParsed[]>([]);
  const [formMode, setFormMode] = useState<'add' | 'edit' | 'duplicate'>('add');
  const [newAppointmentInformation, setNewAppointmentInformation] =
    useState<NewAppointmentInformation>();
  const [onSubmitCallback, setOnSubmitCallback] = useState<() => void>();

  const onAppointmentDetailsClick = useCallback((appointment: AppointmentParsed) => {
    setAppointmentData(appointment);
    setShowDetailsDrawer(true);
  }, []);

  const onAppointmentEditClick = useCallback(
    (
      appointment: AppointmentParsed | AppointmentParsed[] | undefined,
      submitCallback: () => void
    ) => {
      setFormMode('edit');
      setAppointmentEditionData(appointment);
      setOnSubmitCallback(() => submitCallback);
      setShowFormDrawer(true);
    },
    []
  );

  const onEditClickFromDetails = useCallback(() => {
    setShowDetailsDrawer(false);
    onAppointmentEditClick(appointmentData, () => {});
  }, [appointmentData, onAppointmentEditClick]);

  const onAppointmentCreateClick = useCallback((newApptInfo: NewAppointmentInformation) => {
    setFormMode('add');
    setNewAppointmentInformation(newApptInfo);
    setShowFormDrawer(true);
  }, []);

  const onAppointmentDuplicateClick = useCallback(
    (
      appointment: AppointmentParsed | AppointmentParsed[] | undefined,
      submitCallback: () => void
    ) => {
      setFormMode('duplicate');
      setAppointmentEditionData(appointment!);
      setOnSubmitCallback(() => submitCallback);
      setShowFormDrawer(true);
    },
    []
  );

  const onDuplicateAppointmentFromDetails = useCallback(() => {
    setShowDetailsDrawer(false);
    onAppointmentDuplicateClick([appointmentData!], () => {});
  }, [appointmentData, onAppointmentDuplicateClick]);

  const onAppointmentFormCancel = useCallback(() => {
    setFormMode('add');
    setShowFormDrawer(false);
  }, []);

  const onAppointmentDeleteClick = useCallback(
    (appointmentToDelete: AppointmentParsed[], submitCallback: () => void) => {
      setShowDetailsDrawer(false);
      setAppointmentsToDelete(appointmentToDelete);
      setOnSubmitCallback(() => submitCallback);
      setShowConfirmAppointmentDeleteDialog(true);
    },
    []
  );

  const onDeleteAppointmentFromDetails = useCallback(() => {
    onAppointmentDeleteClick([appointmentData!], () => {});
  }, [onAppointmentDeleteClick, appointmentData]);

  const onSubmit = useCallback(() => {
    if (onSubmitCallback) onSubmitCallback();
    setShowFormDrawer(false);
  }, [onSubmitCallback]);

  const onDeleteAppointmentsConfirm = useCallback(async () => {
    loader.show();
    try {
      const ids = appointmentsToDelete.map(({ id }) => id);
      await deleteMultipleAppointments(ids);
      setAppointmentsToDelete([]);
      setShowConfirmAppointmentDeleteDialog(false);
      onSubmitCallback && onSubmitCallback();

      loader.hide();
      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Appointment deleted successfully',
        life: 3000,
      });
    } catch (error: any) {
      loader.hide();
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: error.response?.data?.message || 'The appointment(s) deletion has an error',
        life: 3000,
      });
    }
  }, [appointmentsToDelete, deleteMultipleAppointments, onSubmitCallback, toast]);

  return (
    <Context.Provider
      value={{
        onAppointmentDetailsClick,
        onAppointmentCreateClick,
        onAppointmentEditClick,
        onAppointmentDuplicateClick,
        onAppointmentDeleteClick,
      }}
    >
      {children}
      <AppointmentDetailsDrawer
        appointment={appointmentData}
        onDeleteAppointment={onDeleteAppointmentFromDetails}
        onDuplicateAppointment={onDuplicateAppointmentFromDetails}
        onEditAppointment={onEditClickFromDetails}
        onHide={() => setShowDetailsDrawer(false)}
        visible={showDetailsDrawer}
      />
      <AppointmentForm
        visible={showFormDrawer}
        mode={formMode}
        newAppointmentInfo={newAppointmentInformation}
        appointmentTypesList={appointmentTypes}
        rooms={roomsFilteredOptions}
        providers={providers}
        handleOnHide={onAppointmentFormCancel}
        handleOnSubmit={onSubmit}
        data={appointmentEditionData}
      />

      <ConfirmDeleteDialog
        headerText={
          appointmentsToDelete.length > 1
            ? `Delete ${appointmentsToDelete.length} appointments`
            : 'Delete appointment'
        }
        messageText={
          appointmentsToDelete.length > 1
            ? `Are you sure you want to delete these appointments?`
            : 'Are you sure you want to delete this appointment?'
        }
        visible={showConfirmAppointmentDeleteDialog}
        onCancel={() => setShowConfirmAppointmentDeleteDialog(false)}
        onHide={() => setShowConfirmAppointmentDeleteDialog(false)}
        onConfirm={onDeleteAppointmentsConfirm}
      />
    </Context.Provider>
  );
};

export const useAppointmentActions = () => useContext(Context);
