import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { Link } from 'react-router-dom';

import { useAppointmentTypeContext, useToastContext, useLocation } from '@/contexts';
import { useSchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';
import { env } from '@/utils/constants';
import { useFilters, useProviders, useRooms } from '@/hooks';
import { Room } from '@/@types';
import { Scheduler } from '@/components';
import { SchedulerLayout } from '@/components/Layouts';
import { AthenaStatus } from '@/components/AthenaStatus';

export function SchedulerDailyPage() {
  const { selectedRegion } = useLocation();
  const { findAllViewsForUser, selectCurrentSchedulerView } = useSchedulerSavedViewContext();
  const { findAllRooms } = useRooms();
  const { findAllProviders } = useProviders();
  const { getAppointmentTypes } = useAppointmentTypeContext();
  const { toast } = useToastContext();
  const [showFilters, setShowFilters] = useState(false);
  const { rooms: roomsOptionsList } = useRooms();
  const {
    providers: providersOptionsList,
    equipmentList,
    providersList,
    cmosFilterList,
  } = useProviders();
  const { appointmentTypesOptionsList } = useAppointmentTypeContext();
  const { filters, setFilterItem, isViewingDefaultView, setFilters } = useFilters();

  const roomsFilteredOptions: Room[] = useMemo(() => {
    return roomsOptionsList.filter((room) => room.location?.id === selectedRegion?.id) || [];
  }, [roomsOptionsList, selectedRegion]);

  const initialLoad = useCallback(async () => {
    loader.show();
    try {
      await Promise.all([
        findAllRooms(),
        findAllProviders(),
        findAllViewsForUser(),
        getAppointmentTypes(),
      ]);
      loader.hide();
    } catch (error) {
      toast?.current?.show({
        className: 'text-xl opacity-100',
        severity: 'error',
        summary: 'Failed to load important data',
        detail: `Reload the page. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
        life: 60000,
      });
    }
  }, [findAllProviders, findAllRooms, findAllViewsForUser, getAppointmentTypes, toast]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      location: selectedRegion?.id,
      rooms: [],
      equipment: [],
      personnel: [],
      cmos: [],
      patients: [],
    }));
    selectCurrentSchedulerView(null);
  }, [selectCurrentSchedulerView, selectedRegion?.id, setFilters]);

  return (
    <SchedulerLayout headerComponent={<HeaderButton />} isLocationRequired={true}>
      <div className='h-full p-4'>
        <AthenaStatus />
        <Scheduler
          showFilters={showFilters}
          filters={filters}
          setFilterItem={setFilterItem}
          roomsOptionsList={roomsFilteredOptions}
          equipmentFilterList={equipmentList}
          providersFilterList={providersList}
          providersOptionsList={providersOptionsList}
          appointmentTypesList={appointmentTypesOptionsList}
          cmosFilterList={cmosFilterList}
          setShowFilters={setShowFilters}
          isViewingDefaultView={isViewingDefaultView}
        />
      </div>
    </SchedulerLayout>
  );
}

const HeaderButton = () => {
  return (
    <>
      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_SEARCH_LINK && (
        <Link to='/appointments' className='p-button text-white border-white h-11 px-20'>
          Search Appointments
          <i className='pi pi-search ml-2' />
        </Link>
      )}
    </>
  );
};
