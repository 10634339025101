import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { Controller, useForm } from 'react-hook-form';

import {
  AppointmentType,
  AppointmentWithDate,
  AppointmentWithResourcesList,
  AthenaDepartment,
  Atria,
  ConfirmedTypes,
  Provider,
  Resources,
  SelectOption,
} from '@/@types';
import { Permission, useAuthContext, useToastContext, useLocation } from '@/contexts';
import { DateTimeHelper, SchedulerHelper } from '@/helpers';
import { useSchedulerDuplicateAppointment } from '@/hooks';
import { appointmentsService } from '@/services';
import {
  checkIfEnableSchedulerAthenaSync,
  confirmedTypesOptions,
  getAppointmentAddress,
  isAthenaAppointmentConfirmed,
  isHomeVisit,
} from '@/utils/appointmentUtils';
import { SearchPatientByNameOrId } from '@/components';
import { SearchAddressAutocomplete } from '@/features/AppointmentActions/components';
import styles from './AppointmentForm.module.css';
import {
  AppointmentFormWithTimeSchemaResolver,
  AppointmentWithTimeFormType,
} from './AppointmentFormSchema';
import { env } from '@/utils/constants';
import { ConfirmedStatus } from './ConfirmedStatus';

type NewAppointmentInformation = {
  start?: Date;
  end?: Date;
  resources?: Resources[];
};

type CreateAppointmentFormProps = {
  rooms: SelectOption[];
  providers: Provider[];
  newAppointmentInfo?: NewAppointmentInformation;
  onCancel: () => void;
  onSave: (appointment: Atria.Appointment) => void;
  defaultValues?: AppointmentWithDate | AppointmentWithResourcesList;
  appointmentTypes: AppointmentType[];
};
const now = DateTime.local();

export const CreateAppointmentForm = ({
  rooms,
  providers,
  newAppointmentInfo,
  defaultValues,
  appointmentTypes,
  onCancel,
  onSave,
}: CreateAppointmentFormProps) => {
  const { getAthenaDepartments, getAthenaDepartmentDefault, selectedRegion } = useLocation();
  const [personnelProviders, setPersonnelProviders] = useState<SelectOption[]>([]);
  const [nonPersonnelProviders, setNonPersonnelProviders] = useState<SelectOption[]>([]);
  const [syncToAthena, setSyncToAthena] = useState(false);
  const [roomsPreviousLength, setRoomsPreviousLength] = useState<number>(0);
  const [disabledRooms, setDisabledRooms] = useState<Set<number>>(new Set());
  const { control, formState, watch, setValue, handleSubmit, reset, setFocus } =
    useForm<AppointmentWithTimeFormType>({ resolver: AppointmentFormWithTimeSchemaResolver });
  const { toast } = useToastContext();

  const providersSelected = watch('additionalProvidersSelected');
  const primaryProviderSelected = watch('primaryProviderSelected');
  const liaisonProviderSelected = watch('liaisonProviderSelected');
  const resourcesSelected = watch('resourcesSelected');
  const address = watch('address');
  const appointmentType = watch('appointmentType');
  const appointmentConfirmed = watch('appointmentConfirmed');
  const patient = watch('patient');
  const appointmentDate = watch('date');
  const appointmentStartTime = watch('start');
  const roomsSelected = watch('roomsSelected');
  const athenaDepartment = watch('athenaDepartment');
  const { getDuplicatedAppointmentData } = useSchedulerDuplicateAppointment();

  const { hasPermission } = useAuthContext();
  const roomsOptions = useMemo(() => {
    if (hasPermission(Permission['VIEW_ROOMS:TELE'])) {
      return rooms.filter((room) => room.label.toLowerCase().includes('tele'));
    }

    return rooms;
  }, [rooms, hasPermission]);

  const appointmentTypesOptions = useMemo(() => {
    if (hasPermission(Permission['VIEW_TYPES:TELE'])) {
      return appointmentTypes.filter(
        (type) =>
          type.name.toLowerCase().includes('tele') || type.name.toLowerCase().includes('phone call')
      );
    }

    return appointmentTypes;
  }, [appointmentTypes, hasPermission]);

  const appointmentStartDate = useMemo(() => {
    if (newAppointmentInfo?.start) {
      return newAppointmentInfo?.start.toLocaleString('en-US', {
        timeZone: 'America/New_York',
      });
    }
    return new Date().toLocaleString('en-US');
  }, [newAppointmentInfo?.start]);

  const initialAppointmentStartTime = useMemo(() => {
    let startTime: DateTime = now.startOf('minute').plus({ minutes: 15 - (now.minute % 15) });

    if (newAppointmentInfo && newAppointmentInfo.start && newAppointmentInfo.end) {
      startTime = DateTime.fromJSDate(newAppointmentInfo.start);
    }

    return startTime.toFormat('T');
  }, [newAppointmentInfo]);

  const initialAppointmentEndTime = useMemo(() => {
    let endTime: DateTime = now.startOf('minute').plus({ minutes: 15 - (now.minute % 15) + 15 });

    if (newAppointmentInfo && newAppointmentInfo.end && newAppointmentInfo.end) {
      endTime = DateTime.fromJSDate(newAppointmentInfo.end);
    }

    return endTime.toFormat('T');
  }, [newAppointmentInfo]);

  const filteredProviders = useMemo(() => {
    return personnelProviders.filter(
      (prov) => prov.id !== primaryProviderSelected?.id && prov.id !== liaisonProviderSelected?.id
    );
  }, [liaisonProviderSelected?.id, personnelProviders, primaryProviderSelected?.id]);

  const handleCancelFormButton = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const handleOnPatientSelect = useCallback(
    (data: Atria.FindAllPatients.Response[0] | null) => {
      if (!data) {
        return setValue('patient', null);
      }
      setValue('patient', {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        firstNameUsed: data.firstNameUsed,
        patientName: data.patientName,
        primaryProviderId: data.primaryProvider?.id || null,
      });
    },
    [setValue]
  );

  const removePreviousAppointmentTypeDefaultRoom = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType');

    if (!appointmentTypesSelected) {
      return;
    }

    const previousDefaultRoomsIds: Set<number> = new Set();

    appointmentTypesSelected.defaultRooms?.forEach(({ roomId, restricted }) => {
      if (restricted) {
        setDisabledRooms((prev) => {
          prev.delete(roomId);
          return prev;
        });
      }

      previousDefaultRoomsIds.add(roomId);
    });

    const roomsSelectedWithoutPreviousDefaultRoom = roomsSelected.filter(
      (room) => !previousDefaultRoomsIds.has(room.id)
    );
    setValue('roomsSelected', roomsSelectedWithoutPreviousDefaultRoom);
  }, [setValue, watch, roomsSelected]);

  const removePreviousAppointmentTypeDefaultResource = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType') as AppointmentType;

    if (!appointmentTypesSelected || !selectedRegion) {
      return;
    }

    const previousDefaultResourceIds: Set<number> = new Set();

    appointmentTypesSelected.defaultResources
      ?.filter((resource) => resource.locationId === selectedRegion.id)
      .forEach(({ providerId }) => {
        previousDefaultResourceIds.add(providerId);
      });

    const newSelectedEquipments = resourcesSelected?.filter(
      (resource) => !previousDefaultResourceIds.has(resource.id)
    );

    return setValue('resourcesSelected', newSelectedEquipments);
  }, [setValue, watch, resourcesSelected, selectedRegion]);

  const addAppointmentTypeDefaultResource = useCallback(
    (newType: AppointmentType) => {
      if (!newType) {
        return;
      }

      const defaultResourceIds: Set<number> = new Set();

      newType.defaultResources
        ?.filter((resource) => resource.locationId === selectedRegion?.id)
        .forEach(({ providerId }) => {
          defaultResourceIds.add(providerId);
        });

      const newSelectedResourceIds = new Set([
        ...(resourcesSelected?.map((resource) => resource.id) || []),
        ...defaultResourceIds.values(),
      ]);

      const newSelectedEquipments = nonPersonnelProviders.filter((r) =>
        newSelectedResourceIds.has(r.id)
      );
      return setValue('resourcesSelected', newSelectedEquipments);
    },
    [nonPersonnelProviders, setValue, resourcesSelected, selectedRegion]
  );

  const addAppointmentTypeDefaultRoom = useCallback(
    (newType: AppointmentType) => {
      if (!newType) {
        return;
      }

      const restrictedRoomsIds: Set<number> = new Set();
      const defaultRoomsIds: Set<number> = new Set();

      newType.defaultRooms?.forEach(({ roomId, restricted }) => {
        if (restricted) {
          restrictedRoomsIds.add(roomId);
        }

        if (
          !roomsSelected?.some((roomSelected) => {
            return SchedulerHelper.shouldSkipRoomForSpecificType(roomSelected.id, newType, roomId);
          })
        ) {
          defaultRoomsIds.add(roomId);
        }
      });

      setDisabledRooms((prev) => {
        return new Set([...prev.values(), ...restrictedRoomsIds.values()]);
      });

      const newSelectedRoomsIds = new Set([
        ...(roomsSelected?.map((room) => room.id) || []),
        ...defaultRoomsIds.values(),
        ...(roomsSelected
          ? SchedulerHelper.getRelatedNewRooms(roomsSelected, rooms, newType).map((room) => room.id)
          : []),
      ]);

      const newSelectedRooms = rooms.filter((r) => newSelectedRoomsIds.has(r.id));
      return setValue('roomsSelected', newSelectedRooms);
    },
    [rooms, setValue, roomsSelected]
  );

  const handleAppointmentTypeChange = useCallback(
    async (newType: AppointmentType) => {
      if (!isHomeVisit(newType?.name)) setValue('address', null);

      removePreviousAppointmentTypeDefaultRoom();
      removePreviousAppointmentTypeDefaultResource();
      addAppointmentTypeDefaultRoom(newType);
      addAppointmentTypeDefaultResource(newType);
      setValue('appointmentType', newType);
    },
    [
      addAppointmentTypeDefaultRoom,
      removePreviousAppointmentTypeDefaultRoom,
      addAppointmentTypeDefaultResource,
      removePreviousAppointmentTypeDefaultResource,
      setValue,
    ]
  );

  const handleAthenaDepartmentChange = useCallback(
    async (department: AthenaDepartment) => {
      setValue('athenaDepartment', department);
    },
    [setValue]
  );

  const handleDisabledRoomsOptions = useCallback(
    (option: any): boolean => {
      return disabledRooms.has(option.id);
    },
    [disabledRooms]
  );

  const onSubmit = useCallback(
    async (data: AppointmentWithTimeFormType) => {
      loader.show();
      const formattedStart = DateTime.fromFormat(`${data.date} ${data.start}`, 'M/d/yy T');
      const formattedEnd = DateTime.fromFormat(`${data.date} ${data.end}`, 'M/d/yy T');
      const { minutes: duration } = formattedEnd.diff(formattedStart, 'minutes');

      const appointmentProviders = [
        ...(data.additionalProvidersSelected?.map((p) => ({
          id: p.id,
          name: p.label,
          type: 'ADDITIONAL',
        })) || []),
        ...(data.resourcesSelected?.map((p) => ({
          id: p.id,
          name: p.label,
          type: 'EQUIPMENT',
        })) || []),
      ];

      if (data.liaisonProviderSelected) {
        appointmentProviders.push({
          id: data.liaisonProviderSelected.id,
          name: data.liaisonProviderSelected.label,
          type: 'LIAISON',
        });
      }

      const addressObject = await getAppointmentAddress(data.address?.id || '');

      try {
        const createAppointment: Atria.CreateAppointment.Body = {
          parentId: data.parentId,
          date: formattedStart.toUTC().toISO()!,
          typeId: data.appointmentType?.id,
          type: data.appointmentType?.name,
          ...(data.patient && {
            patient: {
              id: data.patient.id,
              firstName: data.patient.firstName,
              lastName: data.patient.lastName,
              firstNameUsed: data.patient.firstNameUsed,
              primaryProviderId: data.patient?.primaryProviderId || null,
            },
          }),
          duration,
          providers: appointmentProviders,
          rooms: data.roomsSelected?.map(({ id }) => ({ id })) || [],
          title: data.title,
          description: data.description,
          providerId: data.primaryProviderSelected?.id || null,
          providerName: data.primaryProviderSelected?.label || null,
          confirmed: data.appointmentConfirmed,
          athenaDepartmentId: data.athenaDepartment?.departmentId,
          ...addressObject,
        };

        const { data: newAppointment } = createAppointment.parentId
          ? await appointmentsService.duplicateAppointment(createAppointment)
          : await appointmentsService.createAppointment(createAppointment);

        reset();
        onSave(newAppointment);
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Appointment created successfully',
          life: 3000,
        });
      } catch (error: any) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response?.data?.message || 'Failed to create appointment',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [onSave, reset, toast]
  );

  useEffect(() => {
    setFocus('title');
  }, [setFocus]);

  useEffect(() => {
    if (!roomsSelected || !roomsSelected.length || !appointmentType) return;

    if (roomsSelected.length <= roomsPreviousLength) {
      setRoomsPreviousLength(roomsSelected.length);
      return;
    }

    const newValue = SchedulerHelper.getRelatedNewRooms(roomsSelected, rooms, appointmentType);
    setValue('roomsSelected', newValue);
    setRoomsPreviousLength(newValue.length);
  }, [
    roomsSelected,
    rooms,
    roomsPreviousLength,
    setValue,
    setRoomsPreviousLength,
    appointmentType,
  ]);

  // Set end time when an appointment type has a duration
  useEffect(() => {
    if (!syncToAthena) {
      return;
    }
    const matchedAppointmentType: AppointmentType | undefined = appointmentTypes.find(
      (type) => type.id === appointmentType?.id
    );
    if (matchedAppointmentType && matchedAppointmentType.duration) {
      const formattedStart = DateTimeHelper.returnDateTimeForAppointmentStartTime(
        appointmentDate,
        appointmentStartTime
      );
      const endTime: DateTime = formattedStart.plus({ minutes: matchedAppointmentType.duration });
      setValue('end', endTime.toFormat('T'));
    }
  }, [
    appointmentStartTime,
    syncToAthena,
    appointmentDate,
    appointmentType?.id,
    appointmentTypes,
    setValue,
  ]);

  // set initial default athena department
  useEffect(() => {
    if (!athenaDepartment) {
      setValue('athenaDepartment', getAthenaDepartmentDefault());
    }
  }, [athenaDepartment, getAthenaDepartmentDefault, setValue]);

  useEffect(() => {
    setValue('date', appointmentStartDate);
    setValue('end', initialAppointmentEndTime);
  }, [initialAppointmentEndTime, appointmentStartDate, initialAppointmentStartTime, setValue]);

  useEffect(() => {
    if (newAppointmentInfo && newAppointmentInfo.resources) {
      const resourcesRooms =
        newAppointmentInfo.resources
          .filter((r) => r.resourceId?.includes('room'))
          .map((r) => Number(r.resourceId.split('-')[1])) || [];
      const resourcesProviders =
        newAppointmentInfo.resources
          .filter((r) => r.resourceId?.includes('provider'))
          .map((r) => Number(r.resourceId.split('-')[1])) || [];
      const resourcesEquipment =
        newAppointmentInfo.resources
          .filter((r) => r.resourceId?.includes('equipment'))
          .map((r) => Number(r.resourceId.split('-')[1])) || [];
      const [selectedPrimaryProvider, ...appointmentProviders] = personnelProviders.filter(
        (provider) => resourcesProviders.find((r) => r === provider.id)
      );

      const selectedRooms = rooms.filter((room) => resourcesRooms.find((r) => r === room.id));
      setValue('roomsSelected', selectedRooms);

      const selectedEquipment = nonPersonnelProviders.filter((e) =>
        resourcesEquipment.find((r) => r === e.id)
      );

      if (selectedPrimaryProvider) {
        setValue('primaryProviderSelected', selectedPrimaryProvider);
      }
      if (appointmentProviders && appointmentProviders.length > 0) {
        setValue('additionalProvidersSelected', appointmentProviders);
      }
      if (selectedEquipment && selectedEquipment.length > 0) {
        setValue('resourcesSelected', selectedEquipment);
      }
    }
  }, [newAppointmentInfo, rooms, setValue, personnelProviders, nonPersonnelProviders]);

  useEffect(() => {
    const personnel: SelectOption[] = [];
    const nonPersonnel: SelectOption[] = [];

    providers.forEach((provider) => {
      if (provider.type === 'Person') {
        personnel.push({ id: provider.id, label: provider.name });
      } else {
        nonPersonnel.push({ id: provider.id, label: provider.name });
      }
    }, []);

    setPersonnelProviders(personnel);
    setNonPersonnelProviders(nonPersonnel);
  }, [providers]);

  useEffect(() => {
    if (defaultValues) {
      const data = getDuplicatedAppointmentData(defaultValues);
      setValue('parentId', data.parentId);
      setValue('title', data.title);
      setValue('description', data.description);
      setValue('date', data.date);
      setValue('start', data.start);
      setValue('end', data.end);
      setValue('patient', data.patient);
      setValue('roomsSelected', data.roomsSelected);
      setValue('primaryProviderSelected', data.primaryProviderSelected);
      setValue('additionalProvidersSelected', data.additionalProvidersSelected);
      setValue('liaisonProviderSelected', data.liaisonProviderSelected);
      setValue('resourcesSelected', data.resourcesSelected);
      setValue(
        'appointmentType',
        appointmentTypes.find((type) => type.id === defaultValues.typeId)
      );
      setValue('appointmentConfirmed', data.appointmentConfirmed);
      setValue('athenaDepartment', getAthenaDepartmentDefault());
    }
    setValue('atriaAppointment', true);
  }, [
    appointmentTypes,
    defaultValues,
    getDuplicatedAppointmentData,
    setValue,
    getAthenaDepartmentDefault,
  ]);

  // Alert user if the appointment will sync to Athena
  useEffect(() => {
    const isValid = checkIfEnableSchedulerAthenaSync({
      date: appointmentDate,
      providerId: primaryProviderSelected?.id,
      confirmed: isAthenaAppointmentConfirmed(appointmentConfirmed as ConfirmedTypes),
      type: appointmentType?.name,
      patientId: patient?.id,
    });
    setSyncToAthena(isValid);
  }, [appointmentConfirmed, appointmentType, primaryProviderSelected, patient, appointmentDate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.row}>
        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label htmlFor='date' className={styles.label}>
                Date*
              </label>
              <Calendar
                {...field}
                id='date'
                placeholder='Date'
                dateFormat='mm/dd/yy'
                locale='en'
                value={DateTimeHelper.formatDateToUseInCalendar(field.value)}
                className='h-[37px]'
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name='start'
          defaultValue={initialAppointmentStartTime}
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label
                htmlFor='start'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                Start*
              </label>
              <InputText {...field} type='time' className='h-[37px]' />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name='end'
          defaultValue={initialAppointmentEndTime}
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label
                htmlFor='end'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                End*
              </label>
              <InputText disabled={syncToAthena} {...field} type='time' className='h-[37px]' />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='title'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label
                htmlFor='title'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                Title*
              </label>
              <InputText {...field} id='title' placeholder='Title' value={field.value || ''} />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='description'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label htmlFor='description' className={styles.label}>
                Description
              </label>
              <InputTextarea
                {...field}
                id='description'
                placeholder='Description'
                value={field.value || ''}
              />
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <SearchPatientByNameOrId
          defaultFirstName={defaultValues?.firstName}
          defaultLastName={defaultValues?.lastName}
          defaultFirstNameUsed={defaultValues?.firstNameUsed}
          defaultPatientId={defaultValues?.patientId}
          onSelect={handleOnPatientSelect}
        />
      </div>
      {syncToAthena && (
        <div className={styles.row}>
          <Controller
            control={control}
            name='athenaDepartment'
            render={({ field, fieldState }) => (
              <div
                className={classNames(styles.column, {
                  [styles.columnError]: !!fieldState.error,
                })}
              >
                <label htmlFor='athenaDepartment' className={styles.label}>
                  Athena Department
                </label>
                <Dropdown
                  id='athenaDepartment'
                  placeholder='Select a department'
                  filter
                  optionLabel='name'
                  options={getAthenaDepartments()}
                  onChange={(e) => handleAthenaDepartmentChange(e.value)}
                  value={field.value}
                  focusInputRef={field.ref}
                  showClear={true}
                />
                {fieldState.error?.message && (
                  <span className={styles.errorLabel}>{fieldState.error.message}</span>
                )}
              </div>
            )}
          />
        </div>
      )}

      <div className={styles.row}>
        <Controller
          control={control}
          name='appointmentType'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label htmlFor='appointmentType' className={styles.label}>
                Appointment type
              </label>
              <Dropdown
                id='appointmentType'
                placeholder='Select an appointment type'
                filter
                optionLabel='name'
                options={appointmentTypesOptions.sort((a, b) => a.name.localeCompare(b.name))}
                onChange={(e) => handleAppointmentTypeChange(e.value)}
                value={field.value}
                focusInputRef={field.ref}
                showClear={true}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_ADDRESS_APPOINTMENT &&
        isHomeVisit(appointmentType?.name) && (
          <SearchAddressAutocomplete
            address={address}
            setValue={(value) => setValue('address', value)}
          />
        )}

      <div className={styles.row}>
        <Controller
          control={control}
          name='roomsSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames([
                styles.column,
                styles.multiSelectColumn,
                { [styles.columnError]: !!fieldState.error },
              ])}
            >
              <label htmlFor='roomsSelected' className={styles.label}>
                Rooms*
              </label>
              <MultiSelect
                id='roomsSelected'
                placeholder='Select rooms'
                filter
                optionLabel='label'
                options={roomsOptions.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
                optionDisabled={handleDisabledRoomsOptions}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='primaryProviderSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label
                htmlFor='primaryProviderSelected'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                Primary Provider
              </label>
              <Dropdown
                showClear
                id='primaryProviderSelected'
                placeholder='Select the primary provider'
                filter
                optionLabel='label'
                options={personnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e: DropdownChangeEvent) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    providersSelected?.filter((i) => i.id !== provider?.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
              />
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='additionalProvidersSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames([
                styles.column,
                styles.multiSelectColumn,
                {
                  [styles.columnError]: !!fieldState.error,
                },
              ])}
            >
              <label htmlFor='additionalProvidersSelected' className={styles.label}>
                Additional Clinician
              </label>
              <MultiSelect
                id='additionalProvidersSelected'
                placeholder='Select additional staff'
                filter
                optionLabel='label'
                options={filteredProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
              />
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='liaisonProviderSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label
                htmlFor='liaisonProviderSelected'
                className={classNames(styles.label, {
                  [styles.errorLabel]: fieldState.error,
                })}
              >
                Liaison
              </label>
              <Dropdown
                showClear
                id='liaisonProviderSelected'
                placeholder='Select the liaison'
                filter
                optionLabel='label'
                options={
                  primaryProviderSelected
                    ? personnelProviders
                        .filter((p) => p.id !== primaryProviderSelected?.id)
                        .sort((a, b) => a.label.localeCompare(b.label))
                    : personnelProviders.sort((a, b) => a.label.localeCompare(b.label))
                }
                onChange={(e: DropdownChangeEvent) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    providersSelected?.filter((i) => i.id !== provider?.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
                className={styles.dropdownError}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='resourcesSelected'
          render={({ field, fieldState }) => (
            <div
              className={classNames([
                styles.column,
                styles.multiSelectColumn,
                {
                  [styles.columnError]: !!fieldState.error,
                },
              ])}
            >
              <label htmlFor='resourcesSelected' className={styles.label}>
                Resources
              </label>
              <MultiSelect
                id='resourcesSelected'
                placeholder='Select resources'
                filter
                optionLabel='label'
                options={nonPersonnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
              />
            </div>
          )}
        />
      </div>

      <div className={styles.row}>
        <Controller
          control={control}
          name='appointmentConfirmed'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label htmlFor='appointmentConfirmed' className={styles.label}>
                Status:
              </label>

              <ConfirmedStatus
                value={field.value ?? ConfirmedTypes.UNCONFIRMED}
                onChange={(e) => field.onChange(e.value)}
                optionLabel='name'
                options={confirmedTypesOptions}
              />
            </div>
          )}
        />
      </div>

      {syncToAthena && (
        <div className={styles.row}>
          <Message
            className={styles.inlineColumn}
            severity='info'
            text='Your appointment will automatically be created in Athena.'
          />
        </div>
      )}

      <div className='w-full flex flex-col gap-2 mt-4'>
        <Button
          label='Save'
          size='small'
          type='submit'
          className='w-full'
          disabled={Object.values(formState.errors).length > 0}
        />
        <Button
          label='Cancel'
          size='small'
          type='reset'
          className='w-full'
          onClick={handleCancelFormButton}
          outlined
        />
      </div>
    </form>
  );
};
