import { AppointmentAuditAction, ConfirmedType } from './appointment';
import { Nullable } from './common';
import { GoogleEventProvider } from './googleCalendar';

export namespace Atria {
  export type Appointment = {
    id: number;
    appointmentId: number;
    patientId?: number | null;
    patientPrimaryProviderId?: number | null;
    date: string;
    duration: number;
    end: string;
    title: string;
    description?: string;
    type?: string | null;
    typeId?: number | null;
    providerId?: number | null;
    providerName?: string | null;
    status?: string | null;
    patientName?: string;
    firstName?: string | null;
    lastName?: string | null;
    firstNameUsed?: string | null;
    createdAt?: Date | null;
    updatedAt: Date;
    deletedAt?: Date | null;
    rooms: { id: number; name: string }[];
    providers: { id: number; name: string; type: Atria.AppointmentsProviders }[];
    confirmed: ConfirmedType;
    atriaAppointment: boolean;
    encounterId?: number | null;
    encounterState?: string | null;
    checkIn?: string | null;
    checkOut?: string | null;
    updatedBy: string;
    athenaDepartmentId?: number | null;
    patient?: Patient;
    hasConflict?: boolean;
    conflicts?: Conflict[];
  };

  export type Conflict = {
    conflictId: number;
    providerId?: number;
    source: string;
  };

  export type AppointmentAudit = {
    id: number;
    appointmentId: number;
    athenaId: number;
    updatedAt: string;
    authorName: string;
    authorEmail: string;
    action: AppointmentAuditAction;
    dataNew: Appointment;
  };

  export type Patient = {
    id: number;
    firstName: string;
    lastName: string;
    firstNameUsed?: string;
    primaryProviderId?: number;
  };

  export enum AthenaDepartment {
    NY_INSTITUTE = 1,
    NY_HOME_SERVICES = 3,
    NY_PEDIATRICS = 4,
    PB_INSTITUTE = 5,
    PB_HOME_SERVICES = 6,
    PB_PEDIATRICS = 7,
  }

  export const list = ['LIAISON', 'ADDITIONAL', 'EQUIPMENT'] as const;
  export type AppointmentsProviders = (typeof list)[number];
  export enum AppointmentsProvidersEnum {
    LIAISON = 'LIAISON',
    ADDITIONAL = 'ADDITIONAL',
    EQUIPMENT = 'EQUIPMENT',
  }

  export type AppointmentMutation = Atria.CreateAppointment.Response;

  export namespace FindAllAppointments {
    export type Params =
      | {
          startDate: string;
          endDate?: string;
        }
      | {
          rangeQueryStart: string;
          rangeQueryEnd: string;
          rangeQueryField: 'date' | 'createdAt' | 'deletedAt' | 'updatedAt';
          deleteStatus?: 'both' | 'deleted' | 'not-deleted';
          locationId?: number;
          providersIds?: string;
          patientsIds?: string;
          appointmentTypeIds?: string;
        };

    export type Response = Atria.Appointment[];
  }

  export namespace FindAppointmentHistory {
    export type Response = Atria.AppointmentAudit[];
  }

  export namespace CreateAppointment {
    export type Body = {
      parentId?: number;
      title: string;
      description?: string | null;
      date: string;
      providerId?: number | null;
      providerName?: string | null;
      typeId?: number | null;
      type?: string | null;
      patient?: {
        id: number;
        firstName: string;
        lastName: string;
        firstNameUsed?: string | null;
        primaryProviderId?: number | null;
      };
      duration: number;
      providers?: Array<{
        id: number;
        name: string;
        type: string;
      }>;
      rooms?: Array<{
        id: number;
      }>;
      confirmed?: ConfirmedType;
      athenaDepartmentId?: number;
      address?: string;
      addressDetails?: string;
      addressNumber?: string;
      city?: string;
      state?: string;
      zipCode?: string;
      country?: string;
      latitude?: string;
      longitude?: string;
    };

    export type Response = Atria.Appointment;
  }

  export namespace CreateMultipleAppointments {
    export type Body = { appointments: Array<CreateAppointment.Body> };

    export type Response = Array<CreateAppointment.Response>;
  }

  export namespace UpdateMultipleAppointments {
    export type Body = {
      atria?: Atria[];
      athena?: Athena[];
    };

    export type Athena = Partial<{
      id: number;
      roomsIds: number[];
      confirmed: boolean;
    }>;

    export type Atria = Partial<{
      id: number;
      title: string;
      description: string | null;
      date: string;
      roomsIds: number[];
      providerId: number | null;
      providerName: string | null;
      confirmed: ConfirmedType;
      typeId?: number | null;
      type?: string | null;
      duration: number;
      providers: { id: number; name: string }[] | null;
      address?: string;
      addressDetails?: string;
      addressNumber?: string;
      city?: string;
      state?: string;
      zipCode?: string;
      country?: string;
      latitude?: string;
      longitude?: string;
    }>;

    export type Response = void;
  }

  export namespace FindAllPatients {
    export type Params = Partial<{
      firstName: string;
      lastName: string;
      firstNameUsed?: string;
      patientId: number;
    }>;

    export type Response = Array<{
      id: number;
      patientName: string;
      firstName: string;
      lastName: string;
      firstNameUsed?: string;
      dateOfBirth: string;
      primaryProvider?: {
        id: number;
        firstName?: string;
        lastName: string;
        email?: string;
      };
    }>;
  }

  export namespace FindAllAppointmentTypes {
    export type Response = Array<{
      id: number;
      name: string;
      duration: number;
      defaultResources?: {
        id: number;
        typeId: number;
        providerId: number;
        locationId: number;
      }[];
      defaultRooms?: {
        id: number;
        typeId: number;
        roomId: number;
        restricted: boolean;
      }[];
    }>;
  }

  export namespace FindAllGoogleEvents {
    export type Params = {
      providers: string;
      day: string;
      refreshToken: string;
    };

    export type Response = {
      events: Array<{
        title: string;
        start: string;
        end: string;
        type: string;
        typeId: number;
        providerId: number;
        isOutOfOffice: boolean;
        isAllDay: boolean;
      }>;
      eventsOOOAndAllDay: Array<GoogleEventProvider>;
    };
  }

  export namespace GetGoogleToken {
    export type Params = {
      code: string;
    };

    export type Response = {
      refreshToken?: string | null;
      expiryDate?: number | null;
      accessToken?: string | null;
      tokenType?: string | null;
      idToken?: string | null;
      scope?: string;
    };
  }

  export namespace GetGoogleRefreshToken {
    export type Params = {
      refreshToken: string;
    };

    export type Response = {
      refreshToken?: string | null;
      expiryDate?: number | null;
      accessToken?: string | null;
      tokenType?: string | null;
      idToken?: string | null;
      scope?: string;
    };
  }

  export namespace UpdateAppointment {
    export type Body = {
      title: string;
      date: string;
      duration: number;
      description?: string | null;
      providers?: { id: number; name: string }[] | null;
      providerId?: number | null;
      providerName?: string | null;
      rooms: { id: number }[];
      patient?: Atria.Patient | Nullable<Atria.Patient> | null;
      type?: string | null;
      typeId?: number | null;
      confirmed?: ConfirmedType;
      atriaAppointment: boolean;
      address?: string | null;
      addressDetails?: string | null;
      addressNumber?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
      latitude?: string | null;
      longitude?: string | null;
    };

    export type Response = void;
  }

  export namespace RestoreDeletedAppointments {
    export type Body = {
      appointmentIds: number[];
    };

    export type Response = void;
  }
}
