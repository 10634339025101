import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { luxonLocalizer, Views } from 'react-big-calendar';
import { CustomWeek } from './CustomWeek';
import {
  AppointmentType,
  AppointmentWithDate,
  AppointmentWithResourcesList,
  isPatientVisit,
  PatientVisit,
  Provider,
  Resources,
  Room,
} from '@/@types';
import { CustomColumnHeaderProps, CustomToolbarProps } from '@/features/Calendar';

import './WeeklyCalendar.css';
import CustomWithDragAndDrop from '@/features/Calendar/lib/react-big-calendar/lib/addons/customWithDragAndDrop/CustomWithDragAndDrop';
import { Toolbar } from '@/features/Calendar/components';

import { CalendarEvent } from '@/components';
import { SchedulerConstants } from '@/utils/constants';
import { getAppointmentCardClasses, getPatientVisitClasses } from '../../utils';

const localizer = luxonLocalizer(DateTime);

type WeeklyCalendarProps = {
  appointmentTypesList: AppointmentType[];
  currentDate: Date;
  events: any[];
  providersOptionsList: Provider[];
  onAppointmentClick: (event: AppointmentWithResourcesList) => void;
  onCurrentDateChange: (date: Date) => void;
  onCalendarViewSettingsClick: VoidFunction;
  onNewAppointmentClick: VoidFunction;
  onToggleFiltersClick: VoidFunction;
  resources: Resources[];
  roomsOptionsList: Room[];
  showFullCalendar?: boolean;
  suiteRoomsIds: number[];
};

export const WeeklyCalendar = ({
  currentDate,
  events,
  onAppointmentClick,
  onCurrentDateChange,
  onCalendarViewSettingsClick,
  onNewAppointmentClick,
  onToggleFiltersClick,
  resources,
  roomsOptionsList,
  showFullCalendar,
  suiteRoomsIds,
}: WeeklyCalendarProps) => {
  const [calendarTimeRange, setCalendarTimeRange] = useState({
    start: DateTime.fromJSDate(currentDate)
      .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
      .toJSDate(),
    end: DateTime.fromJSDate(currentDate)
      .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
      .toJSDate(),
  });

  const renderNewEvent = useCallback(({ event }: CustomEventProps) => {
    if (isPatientVisit(event)) return;

    return <CalendarEvent appointment={event} />;
  }, []);

  const renderColumnHeader = useCallback((props: CustomColumnHeaderProps) => {
    return <ColumnHeaderComponent date={props.date} />;
  }, []);

  const renderResourceHeader = useCallback(({ label, resource }: ResourceHeaderComponentProps) => {
    return <ResourceHeaderComponent label={label} resource={resource} />;
  }, []);

  const renderToolbar = useCallback(
    (props: CustomToolbarProps) => (
      <Toolbar
        date={props.date}
        dateComponent={<ToolbarDateComponent date={props.date} />}
        hasCalendarPicker={false}
        hasDailyNotes={false}
        onCalendarViewSettingsClick={onCalendarViewSettingsClick}
        onNavigate={props.onNavigate}
        onNewAppointmentClick={onNewAppointmentClick}
        onToggleFiltersClick={onToggleFiltersClick}
        roomsOptionsList={roomsOptionsList}
        suiteRooms={suiteRoomsIds}
      />
    ),
    [
      onCalendarViewSettingsClick,
      onNewAppointmentClick,
      onToggleFiltersClick,
      roomsOptionsList,
      suiteRoomsIds,
    ]
  );

  const eventStyleGetter = useCallback((event: AppointmentWithResourcesList) => {
    if (isPatientVisit(event)) {
      return getPatientVisitClasses();
    }

    return getAppointmentCardClasses(event);
  }, []);

  const calendarProps = useMemo(
    () =>
      ({
        className: 'weekly-calendar',
        components: {
          event: renderNewEvent,
          header: renderColumnHeader,
          resourceHeader: renderResourceHeader,
          toolbar: renderToolbar,
        },
        date: currentDate,
        dayLayoutAlgorithm: 'no-overlap',
        defaultDate: currentDate,
        defaultView: Views.WEEK,
        endAccessor: 'end',
        eventPropGetter: eventStyleGetter,
        events: events,
        formats: {
          timeGutterFormat: (date: Date) => {
            return DateTime.fromJSDate(date).toFormat('h:mm a ');
          },
        },
        getNow: () => currentDate,
        localizer,
        max: calendarTimeRange.end,
        min: calendarTimeRange.start,
        onNavigate: onCurrentDateChange,
        onRangeChange: () => {
          return;
        },
        onSelectEvent: onAppointmentClick,
        resourceIdAccessor: 'resourceId',
        resources,
        resourceTitleAccessor: 'resourceTitle',
        scrollToTime: currentDate,
        showMultiDayTimes: true,
        startAccessor: 'date',
        step: SchedulerConstants.step,
        timeslots: SchedulerConstants.timeslots,
        tooltipAccessor: '' as any,
        views: {
          week: CustomWeek,
        },
      }) as any,
    [
      calendarTimeRange.end,
      calendarTimeRange.start,
      currentDate,
      eventStyleGetter,
      events,
      onAppointmentClick,
      onCurrentDateChange,
      renderColumnHeader,
      renderNewEvent,
      renderResourceHeader,
      renderToolbar,
      resources,
    ]
  );

  useEffect(() => {
    if (showFullCalendar) {
      setCalendarTimeRange({
        start: DateTime.fromJSDate(currentDate).startOf('day').toJSDate(),
        end: DateTime.fromJSDate(currentDate).endOf('day').toJSDate(),
      });
    } else {
      setCalendarTimeRange({
        start: DateTime.fromJSDate(currentDate)
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
          .toJSDate(),
        end: DateTime.fromJSDate(currentDate)
          .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
          .toJSDate(),
      });
    }
  }, [currentDate, showFullCalendar]);

  useEffect(() => {
    const timeColumns = document.querySelectorAll('.rbc-day-slot');

    timeColumns.forEach((column, index) => {
      const dayOfWeekIndex = Math.floor(index / resources.length) % 7;

      if (dayOfWeekIndex === 5 || dayOfWeekIndex === 6) {
        column.classList.add('weekend');
      }
    });
  }, [resources]);

  return (
    <div className='py-2 px-3 bg-white w-full h-full'>
      <CustomWithDragAndDrop {...calendarProps} />
    </div>
  );
};

type CustomEventProps = {
  event: AppointmentWithDate | PatientVisit;
  title: string;
  isVisitEvent?: boolean;
};

type ToolbarDateComponentProps = {
  date: Date;
};

const ToolbarDateComponent = ({ date }: ToolbarDateComponentProps) => {
  const { start, end } = useMemo(() => {
    const startOfWeek = DateTime.fromJSDate(date).startOf('week');
    const endOfWeek = DateTime.fromJSDate(date).endOf('week');
    return {
      start: startOfWeek.toFormat('LLL dd'),
      end: endOfWeek.hasSame(startOfWeek, 'month')
        ? endOfWeek.toFormat('dd')
        : endOfWeek.toFormat('LLL dd'),
    };
  }, [date]);

  return (
    <div className='h-[36px] min-w-[115px] flex justify-center items-center'>
      <span className='text-product-forest-100 text-md font-medium tracking-tight'>{`${start} - ${end}`}</span>
    </div>
  );
};

const ColumnHeaderComponent = ({ date }: ToolbarDateComponentProps) => {
  const dateTimeDate = useMemo(() => DateTime.fromJSDate(date), [date]);
  const isToday = useMemo(() => {
    const today = DateTime.local();
    return (
      dateTimeDate.year === today.year &&
      dateTimeDate.month === today.month &&
      dateTimeDate.day === today.day
    );
  }, [dateTimeDate.day, dateTimeDate.month, dateTimeDate.year]);

  return (
    <div className='flex flex-col justify-center items-center p-[10px]'>
      <span className='text-[10px] leading-3 uppercase text-product-gray tracking-[0.8px] font-medium'>
        {dateTimeDate.toFormat('ccc')}
      </span>
      <span
        className={clsx(
          'text-fern text-[16px] font-medium leading-7 tracking-tight w-[28px] h-[28px]',
          { 'bg-fern text-white rounded-[50%]': isToday }
        )}
      >
        {dateTimeDate.toFormat('dd')}
      </span>
    </div>
  );
};

type ResourceHeaderComponentProps = {
  label: string;
  resource: Resources;
};

const ResourceHeaderComponent = ({ label, resource }: ResourceHeaderComponentProps) => {
  const isClinician = useMemo(
    () => resource.resourceId.includes('provider'),
    [resource.resourceId]
  );

  return (
    <div className='flex flex-col p-[10px] text-center text-[10px] font-medium leading-3 tracking-[0.8px] uppercase'>
      <span className='text-off-black text-pretty'>{label}</span>
      {isClinician && <span className='text-product-gray-400 mt-1'>OFFICE</span>}
    </div>
  );
};
